.accordians {
  align-items: center;
  background-color: #ffffff;
  border-radius: 8px;
  display: flex;
  justify-content: space-between;
  padding: 20px;
  position: relative;
  width: 400px;
}

.accordians .div-3 {
  position: relative;
}

.accordians .text-wrapper-5 {
  color: #3d8634;
  font-family: var(--18-bold-font-family);
  font-size: var(--18-bold-font-size);
  font-style: var(--18-bold-font-style);
  font-weight: var(--18-bold-font-weight);
  letter-spacing: var(--18-bold-letter-spacing);
  line-height: var(--18-bold-line-height);
  margin-top: -1px;
  position: relative;
  width: fit-content;
}

.accordians .text-wrapper-6 {
  color: #626262;
  font-family: var(--16-medium-font-family);
  font-size: var(--16-medium-font-size);
  font-style: var(--16-medium-font-style);
  font-weight: var(--16-medium-font-weight);
  letter-spacing: var(--16-medium-letter-spacing);
  line-height: var(--16-medium-line-height);
  position: relative;
  width: fit-content;
}

.accordians .add {
  height: 24px;
  position: relative;
  width: 24px;
}

.accordians .property-1-3-default {
  color: #2e2e2e;
  font-family: var(--18-bold-font-family);
  font-size: var(--18-bold-font-size);
  font-style: var(--18-bold-font-style);
  font-weight: var(--18-bold-font-weight);
  letter-spacing: var(--18-bold-letter-spacing);
  line-height: var(--18-bold-line-height);
  margin-top: -1px;
  width: fit-content;
}

.accordians .property-1-3-variant-2 {
  align-items: flex-start;
  display: inline-flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 8px;
  width: 680px;
}
