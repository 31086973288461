.bullet {
  align-items: center;
  border-bottom-style: dashed;
  border-bottom-width: 5px;
  display: flex;
  height: 105px;
  justify-content: space-between;
  padding: 0px 32px 0px 90px;
  position: relative;
  width: 588px;
}

.bullet .frame-8 {
  align-items: center;
  display: inline-flex;
  flex: 0 0 auto;
  gap: 32px;
  position: relative;
}

.bullet .m-y-ch-p-h-nh {
  font-family: var(--24-semibold-font-family);
  font-size: var(--24-semibold-font-size);
  font-style: var(--24-semibold-font-style);
  font-weight: var(--24-semibold-font-weight);
  letter-spacing: var(--24-semibold-letter-spacing);
  line-height: var(--24-semibold-line-height);
  margin-top: -1px;
  position: relative;
  width: fit-content;
}

.bullet .frame-96-1 {
  height: 34.06px !important;
  margin-bottom: -1.56px !important;
  position: relative !important;
  width: 33.69px !important;
}

.bullet .photo-camera {
  height: 90px !important;
  position: relative !important;
  width: 90px !important;
}

.bullet.yellow {
  border-color: #d4f470;
}

.bullet.pink {
  border-color: #eca9ee;
}

.bullet.property-1-5-hover.yellow {
  background-color: #e8ef9e;
}

.bullet.pink.property-1-5-hover {
  background-color: #ffc8e2;
}

.bullet.yellow .m-y-ch-p-h-nh {
  color: #377312;
}

.bullet.pink .m-y-ch-p-h-nh {
  color: #ac2ccc;
}
