.component {
  background-image: url(https://generation-sessions.s3.amazonaws.com/d492c08a3017c553696af269c3e39fe1/img/image-7.png);
  background-position: 50% 50%;
  background-size: cover;
  border-radius: 8px;
  height: 450px;
  overflow: hidden;
  width: 562px;
}

.component:hover {
  box-shadow: 8px 8px 0px #b5d358;
}

.component .activity {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  gap: 12px;
  height: 450px;
  justify-content: flex-end;
  padding: 32px;
  position: relative;
  width: 562px;
}

.component .b-i-xe-r-ng {
  align-self: stretch;
  color: #ffffff;
  font-family: "Manrope", Helvetica;
  font-size: 20px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: normal;
  position: relative;
}
