:root {
  --12-regular-font-family: "Manrope-Regular", Helvetica;
  --12-regular-font-size: 12px;
  --12-regular-font-style: normal;
  --12-regular-font-weight: 400;
  --12-regular-letter-spacing: 0px;
  --12-regular-line-height: normal;
  --14-regular-font-family: "Manrope-Regular", Helvetica;
  --14-regular-font-size: 14px;
  --14-regular-font-style: normal;
  --14-regular-font-weight: 400;
  --14-regular-letter-spacing: 0px;
  --14-regular-line-height: normal;
  --14-semibold-font-family: "Manrope-SemiBold", Helvetica;
  --14-semibold-font-size: 14px;
  --14-semibold-font-style: normal;
  --14-semibold-font-weight: 600;
  --14-semibold-letter-spacing: 0px;
  --14-semibold-line-height: normal;
  --16-bold-font-family: "Manrope-Bold", Helvetica;
  --16-bold-font-size: 16px;
  --16-bold-font-style: normal;
  --16-bold-font-weight: 700;
  --16-bold-letter-spacing: 0px;
  --16-bold-line-height: normal;
  --16-medium-font-family: "Manrope-Medium", Helvetica;
  --16-medium-font-size: 16px;
  --16-medium-font-style: normal;
  --16-medium-font-weight: 500;
  --16-medium-letter-spacing: 0px;
  --16-medium-line-height: normal;
  --16-regular-font-family: "Manrope-Regular", Helvetica;
  --16-regular-font-size: 16px;
  --16-regular-font-style: normal;
  --16-regular-font-weight: 400;
  --16-regular-letter-spacing: 0px;
  --16-regular-line-height: normal;
  --18-bold-font-family: "Manrope-Bold", Helvetica;
  --18-bold-font-size: 18px;
  --18-bold-font-style: normal;
  --18-bold-font-weight: 700;
  --18-bold-letter-spacing: 0px;
  --18-bold-line-height: normal;
  --24-semibold-font-family: "Manrope-SemiBold", Helvetica;
  --24-semibold-font-size: 24px;
  --24-semibold-font-style: normal;
  --24-semibold-font-weight: 600;
  --24-semibold-letter-spacing: 0px;
  --24-semibold-line-height: normal;
  --black: rgba(18, 18, 18, 1);
  --button: rgba(160, 190, 67, 1);
  --display-1-font-family: "Manrope-SemiBold", Helvetica;
  --display-1-font-size: 56px;
  --display-1-font-style: normal;
  --display-1-font-weight: 600;
  --display-1-letter-spacing: 0px;
  --display-1-line-height: normal;
  --gray-01: rgba(70, 70, 70, 1);
  --h1-semibold-font-family: "Manrope-SemiBold", Helvetica;
  --h1-semibold-font-size: 40px;
  --h1-semibold-font-style: normal;
  --h1-semibold-font-weight: 600;
  --h1-semibold-letter-spacing: 0px;
  --h1-semibold-line-height: normal;
}
