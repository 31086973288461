.frame {
  align-items: flex-start;
  border-color: #e0e0d1;
  border-top-style: dashed;
  border-top-width: 2px;
  display: flex;
  flex-direction: column;
  padding: 32px;
  position: relative;
  width: 562px;
}

.frame .tittle {
  font-family: var(--16-regular-font-family);
  font-size: var(--16-regular-font-size);
  font-style: var(--16-regular-font-style);
  font-weight: var(--16-regular-font-weight);
  letter-spacing: var(--16-regular-letter-spacing);
  line-height: var(--16-regular-line-height);
  margin-top: -2px;
  position: relative;
  width: fit-content;
}

.frame .div-2 {
  align-items: center;
  display: inline-flex;
  flex: 0 0 auto;
  gap: 8px;
  position: relative;
}

.frame .element-VND {
  color: #eb5567;
  font-family: "Manrope", Helvetica;
  font-size: 32px;
  font-weight: 700;
  letter-spacing: 0;
  line-height: normal;
  margin-top: -1px;
  position: relative;
  width: fit-content;
}

.frame .text-wrapper-3 {
  color: var(--gray-01);
  font-family: var(--16-regular-font-family);
  font-size: var(--16-regular-font-size);
  font-style: var(--16-regular-font-style);
  font-weight: var(--16-regular-font-weight);
  letter-spacing: var(--16-regular-letter-spacing);
  line-height: var(--16-regular-line-height);
  position: relative;
  width: fit-content;
}
.frame .text-wrapper {
  color: #9f9f9f;
  font-family: var(--16-regular-font-family);
  font-size: var(--16-regular-font-size);
  font-style: var(--16-regular-font-style);
  font-weight: var(--16-regular-font-weight);
  letter-spacing: var(--16-regular-letter-spacing);
  line-height: var(--16-regular-line-height);
  position: relative;
  width: fit-content;
}

.frame.property-1-3-hover {
  background-color: #f7f7ea;
}

.frame.property-1-3-hover .tittle {
  color: #464646;
}

.frame.property-1-3-default .tittle {
  color: var(--gray-01);
}
