.homepage {
  background-color: #f4f3e1;
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.homepage .div-4 {
  background-color: #f4f3e1;
  height: 10000px;
  overflow: hidden;
  position: relative;
  width: 1440px;
}

.homepage .frame-9 {
  align-items: flex-start;
  display: inline-flex;
  flex-direction: column;
  left: 0;
  position: absolute;
  top: 800px;
  height: auto
}

.homepage .section {
  align-items: center;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 48px;
  padding: 90px 0px;
  position: relative;
  /* width: 1440px; */
  width: 100%;
}
.homepage .event_poster{
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: relative;
  /* width: 1440px; */
  width: 100%;
}
.event_poster img{
  width: 80%;
}

.register_btn{
  position: sticky;
  padding: 20px;
  
}

/* CSS */
.btn--outline {
  background-color: #fff;
  border: 5 solid #0ba80b;
  border-radius: 1.5rem;
  box-sizing: border-box;
  color: #0d172a;
  cursor: pointer;
  display: inline-block;
  font-family: "Basier circle",-apple-system,system-ui,"Segoe UI",Roboto,"Helvetica Neue",Arial,"Noto Sans",sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol","Noto Color Emoji";
  font-size: 100px;
  font-weight: 1000;
  line-height: 1;
  padding: 1rem 1.6rem;
  text-align: center;
  text-decoration: none #0d172a solid;
  text-decoration-thickness: auto;
  transition: all .1s cubic-bezier(.4, 0, .2, 1);
  box-shadow: 0px 1px 2px rgba(166, 175, 195, 0.25);
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
  width: 280px;
  height: 80px;
}
.btn--outline p{
  font-size: 25px;
  padding-top: 5px;
}

.btn--outline:hover {
  background-color: #0a670a;
  color: #fff;
}

@media (min-width: 768px) {
  .btn--outline {
    font-size: 1.125rem;
    padding: 1rem 2rem;
  }
}

.homepage .d-ch-v-h-p-d-n-t-i {
  color: var(--black);
  font-family: var(--h1-semibold-font-family);
  font-size: var(--h1-semibold-font-size);
  font-style: var(--h1-semibold-font-style);
  font-weight: var(--h1-semibold-font-weight);
  letter-spacing: var(--h1-semibold-letter-spacing);
  line-height: var(--h1-semibold-line-height);
  margin-top: -1px;
  position: relative;
  text-align: center;
  width: 554px;
}

.homepage .frame-10 {
  align-items: center;
  display: inline-flex;
  flex: 0 0 auto;
  gap: 32px;
  position: relative;
}

.homepage .frame-11 {
  align-items: flex-start;
  display: inline-flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 32px;
  position: relative;
}

.homepage .card-instance {
  position: relative !important;
}

.homepage .hiking-1 {
  background-image: url(https://generation-sessions.s3.amazonaws.com/d492c08a3017c553696af269c3e39fe1/img/hiking-1@2x.png) !important;
  height: 120px !important;
  position: relative !important;
  width: 120px !important;
}

.homepage .design-component-instance-node {
  background-image: url(https://generation-sessions.s3.amazonaws.com/d492c08a3017c553696af269c3e39fe1/img/ing-4.png) !important;
}

.homepage .rice-bowl-instance {
  background-image: url(https://generation-sessions.s3.amazonaws.com/d492c08a3017c553696af269c3e39fe1/img/rice-bowl-1@2x.png) !important;
  height: 120px !important;
  position: relative !important;
  width: 120px !important;
}

.homepage .card-2 {
  background-image: url(https://generation-sessions.s3.amazonaws.com/d492c08a3017c553696af269c3e39fe1/img/ing-3.png) !important;
}
.homepage .card-2-1 {
  background-image: url(./Image/Eat.png) !important;
}

.homepage .card-2-2 {
  background-image: url(./Image/Eat1.png) !important;
}

.homepage .card-3 {
  height: 788px !important;
  position: relative !important;
}

.homepage .card-4 {
  background-image: url(https://generation-sessions.s3.amazonaws.com/d492c08a3017c553696af269c3e39fe1/img/ing-2.png) !important;
}

.homepage .card-5 {
  background-color: #ffffff !important;
}

.homepage .card-6 {
  background-color: unset !important;
  height: 810px !important;
  position: relative !important;
}

.homepage .tea-instance {
  background-image: url(https://generation-sessions.s3.amazonaws.com/d492c08a3017c553696af269c3e39fe1/img/tea@2x.png) !important;
  height: 120px !important;
  position: relative !important;
  width: 120px !important;
}

.homepage .card-7 {
  background-image: url(https://generation-sessions.s3.amazonaws.com/d492c08a3017c553696af269c3e39fe1/img/ing-1.png) !important;
}
.homepage .card-7-1 {
  background-image: url(./Image/Cafe.png) !important;
}
.homepage .card-7-2 {
  background-image: url(./Image/Cafe1.png) !important;
}

.homepage .car-instance {
  background-image: url(https://generation-sessions.s3.amazonaws.com/d492c08a3017c553696af269c3e39fe1/img/car@2x.png) !important;
  height: 120px !important;
  position: relative !important;
  width: 120px !important;
}

.homepage .card-8 {
  background-image: url(https://generation-sessions.s3.amazonaws.com/d492c08a3017c553696af269c3e39fe1/img/ing.png) !important;
}

.homepage .section-2 {
  align-items: center;
  background-color: #e3e4b7;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 48px;
  padding: 90px 0px;
  position: relative;
  /* width: 1440px; */
  width: 100%;
}

.homepage .p {
  color: #000000;
  font-family: var(--h1-semibold-font-family);
  font-size: var(--h1-semibold-font-size);
  font-style: var(--h1-semibold-font-style);
  font-weight: var(--h1-semibold-font-weight);
  letter-spacing: var(--h1-semibold-letter-spacing);
  line-height: var(--h1-semibold-line-height);
  margin-top: -1px;
  position: relative;
  text-align: center;
  width: 570px;
}

.homepage .frame-12 {
  align-items: flex-start;
  display: flex;
  flex: 0 0 auto;
  gap: 32px;
  position: relative;
  width: 1138px;
}

.homepage .frame-13 {
  align-items: flex-start;
  background-color: #ffffff;
  border-radius: 8px;
  display: flex;
  flex: 1;
  flex-direction: column;
  flex-grow: 1;
  overflow: hidden;
  position: relative;
  height: 840px;
}

.homepage .frame-14 {
  align-items: flex-start;
  align-self: stretch;
  background-color: #ffffff;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 24px;
  padding: 32px;
  position: absolute;
  width: 100%;
}

.homepage .frame-15 {
  align-items: flex-start;
  display: inline-flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 4px;
  position: relative;
}

.homepage .text-wrapper-7 {
  color: #232323;
  font-family: "Manrope", Helvetica;
  font-size: 28px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: normal;
  margin-top: -1px;
  position: relative;
  width: fit-content;
}

.homepage .text-wrapper-8 {
  color: #464646;
  font-family: var(--16-regular-font-family);
  font-size: var(--16-regular-font-size);
  font-style: var(--16-regular-font-style);
  font-weight: var(--16-regular-font-weight);
  letter-spacing: var(--16-regular-letter-spacing);
  line-height: var(--16-regular-line-height);
  position: relative;
  width: fit-content;
}

.homepage .frame-16 {
  align-items: center;
  display: inline-flex;
  flex: 0 0 auto;
  gap: 12px;
  position: relative;
}

.homepage .frame-17 {
  align-items: center;
  display: inline-flex;
  flex: 0 0 auto;
  gap: 4px;
  position: relative;
}

.homepage .img-2 {
  height: 20px;
  position: relative;
  width: 20px;
}

.homepage .text-wrapper-9 {
  color: #464646;
  font-family: var(--16-regular-font-family);
  font-size: var(--16-regular-font-size);
  font-style: var(--16-regular-font-style);
  font-weight: var(--16-regular-font-weight);
  letter-spacing: var(--16-regular-letter-spacing);
  line-height: var(--16-regular-line-height);
  margin-top: -1px;
  position: relative;
  width: fit-content;
}

.homepage .line {
  height: 16px;
  object-fit: cover;
  position: relative;
  width: 1px;
}

.homepage .leu-lon-a-chinh {
  align-self: stretch;
  height: 341px;
  object-fit: cover;
  position: absolute;
  width: 100%;
  top: 167px;
}
.homepage .leu-lon-a-chinh > div {
  width: 100%;
}

.homepage .frame-22 {
  align-self: stretch !important;
  border-color: unset !important;
  border-top-style: unset !important;
  border-top-width: unset !important;
  flex: 0 0 auto !important;
  width: 100% !important;
  position: absolute;
  bottom: 188px;
}
.homepage .frame-22-01 {
  align-self: stretch !important;
  border-color: unset !important;
  border-top-style: unset !important;
  border-top-width: unset !important;
  flex: 0 0 auto !important;
  width: 100% !important;
  position: absolute;
  bottom: 130px;
}

.homepage .frame-18 {
  align-items: flex-start;
  align-self: stretch;
  background-color: #a0be42;
  display: flex;
  flex: 0 0 auto;
  gap: 10px;
  justify-content: center;
  padding: 20px 24px;
  position: absolute;
  width: 100%;
  bottom: 0;
}

.homepage .text-wrapper-10 {
  color: #ffffff;
  font-family: "Inter", Helvetica;
  font-size: 18px;
  font-weight: 700;
  letter-spacing: 0;
  line-height: normal;
  margin-top: -1px;
  position: relative;
  width: fit-content;
}

.homepage .arrow-forward-2 {
  height: 24px;
  position: relative;
  width: 24px;
}

.homepage .leu-lon-a-chinh-2 {
  align-self: stretch;
  height: 341px;
  position: absolute;
  top: 167px;
  width: 100%;
}

.homepage .design-component-instance-node-2 {
  align-self: stretch !important;
  flex: 0 0 auto !important;
  width: 100% !important;
}
.homepage .design-component-instance-node-3 {
  align-self: stretch !important;
  flex: 0 0 auto !important;
  width: 100% !important;
  position: absolute;
  bottom: 64px;
}

.homepage .frame-19 {
  align-items: flex-start;
  align-self: stretch;
  background-color: var(--button);
  display: flex;
  flex: 0 0 auto;
  gap: 10px;
  justify-content: center;
  padding: 20px 24px;
  position: absolute;
  width: 100%;
  bottom: 0;
}

.homepage .frame-wrapper {
  background: linear-gradient(
    180deg,
    rgba(16.58, 47.81, 8.77, 0.9) 0%,
    rgba(37.5, 56.31, 6.8, 0.6) 100%
  );
  background-image: url(./Image/section.png);
  /* background-image: url(https://generation-sessions.s3.amazonaws.com/d492c08a3017c553696af269c3e39fe1/img/section-1.png); */
  background-position: 50% 50%;
  background-size: cover;
  height: 900px;
  position: relative;
  /* width: 1440px; */
  width: 100%;
}

.homepage .frame-20 {
  align-items: center;
  display: inline-flex;
  flex-direction: column;
  gap: 48px;
  left: 150px;
  position: relative;
  top: 153px;
}

.homepage .frame-21 {
  align-items: center;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 12px;
  position: relative;
  width: 554px;
}

.homepage .div-wrapper {
  align-items: center;
  display: inline-flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 4px;
  position: relative;
}

.homepage .text-wrapper-11 {
  color: #ffffff;
  font-family: var(--h1-semibold-font-family);
  font-size: var(--h1-semibold-font-size);
  font-style: var(--h1-semibold-font-style);
  font-weight: var(--h1-semibold-font-weight);
  letter-spacing: var(--h1-semibold-letter-spacing);
  line-height: var(--h1-semibold-line-height);
  margin-top: -1px;
  position: relative;
  /* width: fit-content; */
  width: 720px;
}
.homepage .text-wrapper-11-1 {
  color: #ffffff;
  font-family: var(--h1-semibold-font-family);
  font-size: var(--h1-semibold-font-size);
  font-style: var(--h1-semibold-font-style);
  font-weight: var(--h1-semibold-font-weight);
  letter-spacing: var(--h1-semibold-letter-spacing);
  line-height: var(--h1-semibold-line-height);
  margin-top: -1px;
  position: relative;
  width: fit-content;
  /* width: 720px; */
}

.homepage .text-wrapper-12 {
  color: #ffffff;
  font-family: "Manrope", Helvetica;
  font-size: 18px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: normal;
  opacity: 0.7;
  position: relative;
  text-align: center;
  width: 554px;
}

.homepage .frame-23 {
  align-items: center;
  display: inline-flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 32px;
  position: relative;
}

.homepage .frame-24 {
  align-items: flex-start;
  display: inline-flex;
  flex: 0 0 auto;
  gap: 32px;
  position: relative;
}

.homepage .frame-25 {
  align-items: center;
  display: flex;
  flex-direction: column;
  gap: 20px;
  position: relative;
  width: 261px;
}

.homepage .icon-instance-node {
  height: 90px !important;
  position: relative !important;
  width: 90px !important;
}

.homepage .text-wrapper-13 {
  align-self: stretch;
  color: #ffffff;
  font-family: var(--16-regular-font-family);
  font-size: var(--16-regular-font-size);
  font-style: var(--16-regular-font-style);
  font-weight: var(--16-regular-font-weight);
  letter-spacing: var(--16-regular-letter-spacing);
  line-height: var(--16-regular-line-height);
  position: relative;
  text-align: center;
}

.homepage .group {
  height: 90px;
  position: relative;
  width: 59.11px;
}

.homepage .frame-26 {
  align-items: flex-start;
  background-color: #a0be42;
  display: inline-flex;
  flex: 0 0 auto;
  gap: 10px;
  justify-content: center;
  padding: 20px 24px;
  position: relative;
}

.homepage .frame-27 {
  align-items: flex-start;
  display: flex;
  gap: 16px;
  height: 450px;
  position: relative;
  width: 1140px;
}

.homepage .component-1 {
  align-self: stretch !important;
  background-image: url(https://generation-sessions.s3.amazonaws.com/d492c08a3017c553696af269c3e39fe1/img/image-5@2x.png) !important;
  flex: 1 !important;
  flex-grow: 1 !important;
  height: unset !important;
  position: relative !important;
  width: unset !important;
}

.homepage .component-instance {
  width: 273px !important;
}

.homepage .component-1-instance {
  align-self: stretch !important;
  background-image: url(https://generation-sessions.s3.amazonaws.com/d492c08a3017c553696af269c3e39fe1/img/image-3@2x.png) !important;
  flex: 1 !important;
  flex-grow: 1 !important;
  height: unset !important;
  position: relative !important;
  width: unset !important;
}

.homepage .component-2 {
  align-self: stretch !important;
  background-image: url(https://generation-sessions.s3.amazonaws.com/d492c08a3017c553696af269c3e39fe1/img/image-1@2x.png) !important;
  flex: 1 !important;
  flex-grow: 1 !important;
  height: unset !important;
  position: relative !important;
  width: unset !important;
}

.homepage .component-3 {
  align-self: stretch !important;
  background-image: url(https://generation-sessions.s3.amazonaws.com/d492c08a3017c553696af269c3e39fe1/img/image@2x.png) !important;
  flex: 1 !important;
  flex-grow: 1 !important;
  height: unset !important;
  position: relative !important;
  width: unset !important;
}

.homepage .frame-28 {
  height: 652px;
  position: relative;
  width: 1140px;
}

.homepage .frame-29 {
  align-items: flex-start;
  display: flex;
  flex-wrap: wrap;
  gap: 16px 16px;
  position: relative;
  width: 1544px;
}

.homepage .testimonials-instance {
  font-family: "Manrope", Helvetica !important;
  font-size: 12px !important;
  font-style: unset !important;
  font-weight: 400 !important;
  letter-spacing: 0 !important;
  line-height: normal !important;
}

.homepage .testimonials-2 {
  width: 6.71px !important;
}

.homepage .testimonials-3 {
  font-family: "Manrope", Helvetica !important;
  font-size: 14px !important;
  font-style: unset !important;
  font-weight: 600 !important;
  letter-spacing: 0 !important;
  line-height: normal !important;
}

.homepage .rectangle-2 {
  height: 990px;
  left: 1054px;
  position: absolute;
  top: 0;
  width: 386px;
}

.homepage .overlap-wrapper {
  background-image: url(./Image/frame115.png);
  /* background-image: url(https://generation-sessions.s3.amazonaws.com/d492c08a3017c553696af269c3e39fe1/img/frame-115.png); */
  background-position: 50% 50%;
  background-size: cover;
  height: 1440.03px;
  position: relative;
  width: 1440px;
}

.homepage .overlap {
  height: 1436px;
  left: 33px;
  position: relative;
  top: 22px;
  width: 1407px;
}

.homepage .overlap-group {
  height: 1332px;
  left: 0;
  position: absolute;
  top: 0;
  width: 1407px;
}

.homepage .vector {
  height: 178px;
  left: 1156px;
  position: absolute;
  top: 1154px;
  width: 251px;
}

.homepage .vector-2 {
  height: 454px;
  left: 673px;
  position: absolute;
  top: 345px;
  width: 292px;
}

.homepage .pink-note {
  width: 554px;
  height: 719px;
  position: absolute;
  top: 210px;
  left: 127px;
  /* background-image: url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iNTY0IiBoZWlnaHQ9IjcyOSIgdmlld0JveD0iMCAwIDU2NCA3MjkiIGZpbGw9Im5vbmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+CjxnIGZpbHRlcj0idXJsKCNmaWx0ZXIwX2RfOTdfMjA1MikiPgo8cGF0aCBkPSJNNSAxTDU1OSAxVjY5Ni4xNTFDMzk4LjMyNyA3MjIuNjIgMzUzLjQyMSA3MTkuOTIzIDUgNzE5LjkyM0w1IDFaIiBmaWxsPSIjRkZEREVEIi8+CjwvZz4KPGRlZnM+CjxmaWx0ZXIgaWQ9ImZpbHRlcjBfZF85N18yMDUyIiB4PSIwIiB5PSIwIiB3aWR0aD0iNTY0IiBoZWlnaHQ9IjcyOSIgZmlsdGVyVW5pdHM9InVzZXJTcGFjZU9uVXNlIiBjb2xvci1pbnRlcnBvbGF0aW9uLWZpbHRlcnM9InNSR0IiPgo8ZmVGbG9vZCBmbG9vZC1vcGFjaXR5PSIwIiByZXN1bHQ9IkJhY2tncm91bmRJbWFnZUZpeCIvPgo8ZmVDb2xvck1hdHJpeCBpbj0iU291cmNlQWxwaGEiIHR5cGU9Im1hdHJpeCIgdmFsdWVzPSIwIDAgMCAwIDAgMCAwIDAgMCAwIDAgMCAwIDAgMCAwIDAgMCAxMjcgMCIgcmVzdWx0PSJoYXJkQWxwaGEiLz4KPGZlT2Zmc2V0IGR5PSI0Ii8+CjxmZUdhdXNzaWFuQmx1ciBzdGREZXZpYXRpb249IjIuNSIvPgo8ZmVDb21wb3NpdGUgaW4yPSJoYXJkQWxwaGEiIG9wZXJhdG9yPSJvdXQiLz4KPGZlQ29sb3JNYXRyaXggdHlwZT0ibWF0cml4IiB2YWx1ZXM9IjAgMCAwIDAgMCAwIDAgMCAwIDAgMCAwIDAgMCAwIDAgMCAwIDAuMjUgMCIvPgo8ZmVCbGVuZCBtb2RlPSJub3JtYWwiIGluMj0iQmFja2dyb3VuZEltYWdlRml4IiByZXN1bHQ9ImVmZmVjdDFfZHJvcFNoYWRvd185N18yMDUyIi8+CjxmZUJsZW5kIG1vZGU9Im5vcm1hbCIgaW49IlNvdXJjZUdyYXBoaWMiIGluMj0iZWZmZWN0MV9kcm9wU2hhZG93Xzk3XzIwNTIiIHJlc3VsdD0ic2hhcGUiLz4KPC9maWx0ZXI+CjwvZGVmcz4KPC9zdmc+Cg=="); */
}

.homepage .yellow-note {
  height: 580px;
  left: 703px;
  position: absolute;
  top: 648px;
  width: 554px;
}

.homepage .overlap-2 {
  height: 628px;
  left: -10px;
  position: relative;
  top: -3px;
  width: 609px;
}

.homepage .rectangle-3 {
  height: 304px;
  left: 0;
  position: absolute;
  top: 324px;
  width: 609px;
}
.homepage .rectangle-11 {
  height: 304px;
  left: 0;
  position: absolute;
  top: 480px;
  width: 609px;
}

.homepage .rectangle-4 {
  height: 595px;
  left: 2px;
  position: absolute;
  top: 0;
  width: 570px;
}
.homepage .rectangle-10 {
  height: 100%;
  left: 2px;
  position: absolute;
  top: 0;
  width: 100%;
}

.homepage .frame-30 {
  align-items: center;
  display: inline-flex;
  flex-direction: column;
  gap: 24px;
  left: 10px;
  position: absolute;
  top: 103px;
}
.homepage .frame-50 {
  align-items: center;
  display: inline-flex;
  flex-direction: column;
  gap: 24px;
  left: 10px;
  position: absolute;
  top: 75px;
}

.homepage .overlap-group-wrapper {
  height: 56.04px;
  position: relative;
  width: 275.05px;
}

.homepage .overlap-group-2 {
  height: 57px;
  position: relative;
  top: -1px;
  width: 275px;
}
.homepage .overlap-group-3 {
  height: 57px;
  position: relative;
  top: -1px;
  width: 275px;
  background-size: cover;
  background-image: url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjQ1IiBoZWlnaHQ9IjYyIiB2aWV3Qm94PSIwIDAgMjQ1IDYyIiBmaWxsPSJub25lIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciPgo8cGF0aCBkPSJNMC43NSA3LjVWNjJIMjI2LjI1TDI0NC4yNSAwTDUwLjc1IDcuNUgwLjc1WiIgZmlsbD0iI0ZGQTVEMCIvPgo8L3N2Zz4K");
}

.homepage .text-wrapper-14 {
  color: #000000;
  font-family: "Margarine", Helvetica;
  font-size: 40px;
  font-weight: 400;
  left: 20px;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  top: 0;
  transform: rotate(-0.06deg);
  width: 250px;
}
.homepage .text-wrapper-31 {
  color: #000000;
  font-family: "Margarine", Helvetica;
  font-size: 40px;
  font-weight: 400;
  left: 41px;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  top: 0;
  transform: rotate(-0.06deg);
  width: 245px;
  /* background-image: url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjQ1IiBoZWlnaHQ9IjYyIiB2aWV3Qm94PSIwIDAgMjQ1IDYyIiBmaWxsPSJub25lIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciPgo8cGF0aCBkPSJNMC43NSA3LjVWNjJIMjI2LjI1TDI0NC4yNSAwTDUwLjc1IDcuNUgwLjc1WiIgZmlsbD0iI0ZGQTVEMCIvPgo8L3N2Zz4K"); */
}

.homepage .vector-3 {
  height: 54px;
  left: 0;
  position: absolute;
  top: 3px;
  width: 275px;
}
.homepage .vector-4 {
  height: 54px;
  left: 0;
  position: absolute;
  top: 3px;
  width: 275px;
  background-size: cover;
}

.homepage .frame-31 {
  align-items: flex-start;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  position: relative;
  width: 553.71px;
}
.homepage .frame-51 {
  align-items: flex-start;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  position: relative;
  width: 540px;
}

.homepage .bullet-instance {
  align-self: stretch !important;
  margin-left: -2.5px !important;
  margin-right: -2.5px !important;
  margin-top: -2.5px !important;
  width: 100% !important;
}

.homepage .frame-32 {
  height: 34.06px !important;
  margin-bottom: -1.56px !important;
  position: relative !important;
  width: 33.69px !important;
}

.homepage .bullet-2 {
  align-self: stretch !important;
  margin-left: -2.5px !important;
  margin-right: -2.5px !important;
  width: 100% !important;
}

.homepage .bullet-3 {
  align-self: stretch !important;
  margin-bottom: -2.5px !important;
  margin-left: -2.5px !important;
  margin-right: -2.5px !important;
  width: 100% !important;
}

.homepage .fujifilm-instax-wide {
  background-color: #ffffff;
  box-shadow: 0px 0px 12px #0000001a;
  height: 275px;
  left: 910px;
  position: absolute;
  top: 255px;
  transform: rotate(-19.44deg);
  width: 344px;
}

.homepage .picture-fujifilm {
  height: 293px;
  left: -10px;
  position: absolute;
  top: -24px;
  transform: rotate(19.44deg);
  width: 365px;
}

.homepage .frame-33 {
  align-items: center;
  display: flex;
  flex-direction: column;
  gap: 12px;
  left: 410px;
  position: absolute;
  top: 54px;
  width: 554px;
}

.homepage .text-wrapper-15 {
  color: var(--black);
  font-family: "Manrope", Helvetica;
  font-size: 40px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: normal;
  margin-top: -1px;
  position: relative;
  width: fit-content;
}

.homepage .c-ng-i-m-danh-qua-nh {
  color: var(--black);
  font-family: "Manrope", Helvetica;
  font-size: 18px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: normal;
  opacity: 0.7;
  position: relative;
  text-align: center;
  width: 554px;
}

.homepage .fitness-tracker {
  height: 114px;
  left: 827px;
  position: absolute;
  top: 25px;
  transform: rotate(53.21deg);
  width: 120px;
}

.homepage .fitness-tracker-2 {
  height: 164px;
  left: -22px;
  object-fit: cover;
  position: absolute;
  top: -25px;
  transform: rotate(-53.21deg);
  width: 163px;
}

.homepage .polaroid-format {
  background-color: #ffffff;
  box-shadow: 0px 0px 12px #0000001a;
  height: 347px;
  left: 122px;
  position: absolute;
  top: 1055px;
  transform: rotate(16.96deg);
  width: 284px;
}

.homepage .picture-polaroid {
  height: 317px;
  left: -16px;
  position: absolute;
  top: -11px;
  transform: rotate(-16.96deg);
  width: 316px;
}

.homepage .fujifilm-instax-mini {
  background-color: #ffffff;
  box-shadow: 0px 0px 12px #0000001a;
  height: 308px;
  left: 415px;
  position: absolute;
  top: 1019px;
  transform: rotate(-12.19deg);
  width: 193px;
}

.homepage .picture-fujifilm-2 {
  height: 249px;
  left: -3px;
  position: absolute;
  top: 11px;
  transform: rotate(12.19deg);
  width: 200px;
}

.homepage .frame-34 {
  align-items: center;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 32px;
  padding: 90px 0px;
  position: relative;
  width: 1440px;
}

.homepage .text-wrapper-16 {
  color: var(--black);
  font-family: var(--h1-semibold-font-family);
  font-size: var(--h1-semibold-font-size);
  font-style: var(--h1-semibold-font-style);
  font-weight: var(--h1-semibold-font-weight);
  letter-spacing: var(--h1-semibold-letter-spacing);
  line-height: var(--h1-semibold-line-height);
  margin-top: -1px;
  position: relative;
  width: fit-content;
}

.homepage .frame-35 {
  align-items: flex-start;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 8px;
  position: relative;
  width: 750px;
}

.homepage .overlap-3 {
  height: 1000px;
  left: 0;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  width: auto;
}

.homepage .logo-instance {
  height: 60px !important;
  left: unset !important;
  position: relative !important;
  top: unset !important;
  width: 140px !important;
}

.homepage .menu {
  align-items: center;
  display: inline-flex;
  flex: 0 0 auto;
  gap: 10px;
  justify-content: center;
  padding: 10px;
  position: relative;
}

.homepage .text-wrapper-17 {
  color: #000000;
  font-family: "Manrope", Helvetica;
  font-size: 18px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: normal;
  margin-top: -1px;
  position: relative;
  width: fit-content;
}

.homepage .rectangle-5 {
  background-color: #000000;
  height: 11px;
  position: relative;
  width: 1px;
}

.homepage .text-wrapper-18 {
  color: #bee24f;
  font-family: "Manrope", Helvetica;
  font-size: 18px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: normal;
  margin-top: -1px;
  position: relative;
  width: fit-content;
  border: none;
  background-color: inherit;
}

.homepage .text-wrapper-18-1 {
  color: #c97539;
  font-family: "Manrope", Helvetica;
  font-size: 18px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: normal;
  margin-top: -1px;
  position: relative;
  width: fit-content;
  border: none;
  background-color: inherit;
}

.homepage .call {
  height: 24px;
  position: relative;
  width: 24px;
}

.homepage .text-wrapper-19 {
  color: #000000;
  font-family: "Inter", Helvetica;
  font-size: 12px;
  font-weight: 400;
  left: 77px;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  top: 514px;
  width: 150px;
}

.homepage .section-3 {
  /* background-color: #252e0699; */
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  /* background-image: url("./Image/z4706710904170_613bbfa083cccddcc8f21df3a5924f00.png"); */
  /* background-image: url(https://generation-sessions.s3.amazonaws.com/d492c08a3017c553696af269c3e39fe1/img/section.png); */
  background-position: 50% 50%;
  background-size: cover;
  height: 750px;
  left: 0;
  position: absolute;
  top: 0;
  width: 1440px;
  width: 100%;
}
.homepage .ch-o-m-ng-b-n-n-t-i {
  color: #f7f150;
  font-family: var(--display-1-font-family);
  font-size: var(--display-1-font-size);
  font-style: var(--display-1-font-style);
  font-weight: var(--display-1-font-weight);
  left: 290px;
  letter-spacing: var(--display-1-letter-spacing);
  line-height: var(--display-1-line-height);
  position: absolute;
  text-align: center;
  top: 323px;
}
.homepage .subtitle {
  background-color: #ffffffcc;
  border-radius: 1000px;
  display: inline-flex;
  gap: 10px;
  padding: 6px 20px;
  color: #000000;
  font-family: var(--display-1-font-family);
  /* font-size: var(--display-1-font-size); */
  font-size: 24px;
  font-style: var(--display-1-font-style);
  font-weight: var(--display-1-font-weight);
  left: 500px;
  letter-spacing: var(--display-1-letter-spacing);
  line-height: var(--display-1-line-height);
  position: absolute;
  text-align: center;
  top: 465px;
  /* text-shadow: 3px 3px 3px #000000; */
}

.each-slide {
  width: 1440px;
  height: 800px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 1;
  position: absolute;
  background-size: cover;
}
.each-slide > div {
  width: 100%;
}

.each-slide > div img {
  width: 1440px;
  height: 800px;
  object-fit: cover;
}

/* .each-slide p {
  color: #ffffff;
  font-family: var(--display-1-font-family);
  font-size: var(--display-1-font-size);
  font-style: var(--display-1-font-style);
  font-weight: var(--display-1-font-weight);
  text-shadow: -1px 1px 0 #000, 1px 1px 0 #000, 1px -1px 0 #000,
    -1px -1px 0 #000;
  left: 240px;
  letter-spacing: var(--display-1-letter-spacing);
  line-height: var(--display-1-line-height);
  position: absolute;
  text-align: center;
  top: 323px;
} */
/* .header {
  position: fixed;
  height: 90px;
  width: 100%;
  top: 0;
  left: 0;
  z-index: 1;
  transition: color 0.3s ease-in;
  overflow: hidden;
  align-items: center;
} */

.header-nav {
  align-items: center;
  display: flex;
  height: 80px;
  justify-content: space-between;
  left: 0;
  padding: 0px 120px;
  position: fixed;
  top: 0;
  bottom: 0;
  width: 100%;
  /* background-color: #0d3102; */
  /* background-color: rgba(18, 95, 8, 0.548); */
}

.homepage .text-wrapper-20 {
  /* color: #000000; */
  color :#242005;
  font-family: "Manrope", Helvetica;
  font-size: 18px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: normal;
  margin-top: -1px;
  position: relative;
  width: fit-content;
  border: none;
  background-color: inherit;
}

.homepage .rectangle-9 {
  background-color: #000000;
  height: 11px;
  position: relative;
  width: 1px;
}

.homepage .overlap-4 {
  /* height: 295px; */
  left: 0;
  position: relative;
  /* top: 9900px; */
  width: 100%;
}

.homepage .frame-36 {
  align-items: center;
  display: flex;
  flex-direction: column;
  left: 0;
  position: relative;
  top: 0;
  width: 100%;
  justify-content: flex-end;
  bottom: 0;
}

.homepage .footer {
  align-items: center;
  background: linear-gradient(
    180deg,
    rgba(16.58, 47.81, 8.77, 0.9) 0%,
    rgba(37.5, 56.31, 6.8, 0.6) 100%
  );
  /* background-image: url(https://generation-sessions.s3.amazonaws.com/d492c08a3017c553696af269c3e39fe1/img/footer.png); */
  background-image: url(./Image/footer.png);
  background-position: 50% 50%;
  background-size: cover;
  display: flex;
  flex: 0 0 auto;
  gap: 32px;
  justify-content: center;
  padding: 90px 0px;
  position: relative;
  width: 1440px;
}
/* /align-items: center;
display: flex;
height: 80px;
justify-content: space-between;
left: 0;
padding: 0px 120px;
position: fixed;
top: 0;
bottom: 0;
width: 100%;
background-color: #2e7f15; */

.homepage .frame-37 {
  align-items: flex-start;
  display: flex;
  gap: 32px;
  position: relative;
  width: 1140px;
}

.homepage .frame-38 {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  gap: 16px;
  position: relative;
  width: 359px;
}

.homepage .frame-39 {
  align-items: flex-start;
  display: inline-flex;
  flex: 0 0 auto;
  flex-direction: column;
  position: relative;
}

.homepage .text-wrapper-21 {
  color: #ffffffcc;
  font-family: "Manrope", Helvetica;
  font-size: 20px;
  font-weight: 700;
  letter-spacing: 0;
  line-height: normal;
  margin-top: -1px;
  position: relative;
  width: fit-content;
}

.homepage .text-wrapper-22 {
  color: #ffffffcc;
  font-family: var(--14-regular-font-family);
  font-size: var(--14-regular-font-size);
  font-style: var(--14-regular-font-style);
  font-weight: var(--14-regular-font-weight);
  letter-spacing: var(--14-regular-letter-spacing);
  line-height: var(--14-regular-line-height);
  position: relative;
  width: 100%;
}

.homepage .frame-40 {
  align-items: flex-start;
  display: flex;
  justify-content: space-between;
  position: relative;
  width: 749px;
}

.homepage .frame-41 {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  gap: 16px;
  justify-content: center;
  position: relative;
  width: 261px;
}

.homepage .text-wrapper-23 {
  color: #ffffffcc;
  font-family: "Manrope", Helvetica;
  font-size: 16px;
  font-weight: 700;
  letter-spacing: -0.32px;
  line-height: normal;
  margin-top: -1px;
  position: relative;
  width: fit-content;
}

.homepage .text-wrapper-24 {
  align-self: stretch;
  color: #ffffffcc;
  font-family: var(--14-regular-font-family);
  font-size: var(--14-regular-font-size);
  font-style: var(--14-regular-font-style);
  font-weight: var(--14-regular-font-weight);
  letter-spacing: var(--14-regular-letter-spacing);
  line-height: var(--14-regular-line-height);
  position: relative;
}

.homepage .frame-42 {
  align-items: flex-start;
  display: inline-flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 16px;
  position: relative;
}

.homepage .text-wrapper-25 {
  color: #ffffff;
  font-family: var(--14-regular-font-family);
  font-size: var(--14-regular-font-size);
  font-style: var(--14-regular-font-style);
  font-weight: var(--14-regular-font-weight);
  letter-spacing: var(--14-regular-letter-spacing);
  line-height: var(--14-regular-line-height);
  position: relative;
  width: fit-content;
}

.homepage .frame-43 {
  align-items: flex-start;
  display: inline-flex;
  flex: 0 0 auto;
  gap: 24px;
  position: relative;
}

.homepage .frame-44 {
  align-items: center;
  background-color: #000000;
  display: flex;
  flex: 0 0 auto;
  gap: 10px;
  justify-content: center;
  padding: 10px 0px;
  position: relative;
  width: 1440px;
}

.homepage .text-wrapper-26 {
  color: #ffffffcc;
  font-family: "Manrope", Helvetica;
  font-size: 14px;
  font-weight: 500;
  letter-spacing: -0.28px;
  line-height: normal;
  margin-top: -1px;
  position: relative;
  width: fit-content;
}

.homepage .frame-45 {
  align-items: flex-start;
  display: inline-flex;
  flex-direction: column;
  gap: 8px;
  left: 1261px;
  position: absolute;
  top: 148px;
}

.homepage .frame-46 {
  align-items: center;
  background-color: #ffffff;
  border-radius: 100px;
  display: inline-flex;
  flex: 0 0 auto;
  gap: 8px;
  padding: 8px 16px 9px;
  position: relative;
}

.homepage .image {
  height: 28px;
  object-fit: cover;
  position: relative;
  width: 28px;
}

.homepage .frame-47 {
  align-items: flex-start;
  display: inline-flex;
  flex: 0 0 auto;
  flex-direction: column;
  justify-content: center;
  position: relative;
}

.homepage .text-wrapper-27 {
  color: var(--gray-01);
  font-family: "Manrope", Helvetica;
  font-size: 14px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: normal;
  margin-top: -1px;
  position: relative;
  width: fit-content;
}

.homepage .text-wrapper-28 {
  color: var(--gray-01);
  font-family: "Manrope", Helvetica;
  font-size: 12px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: normal;
  position: relative;
  width: fit-content;
}

.homepage .frame-48 {
  align-items: center;
  background-color: #ffffff;
  border-radius: 100px;
  display: flex;
  flex: 0 0 auto;
  gap: 8px;
  padding: 12px 16px;
  position: relative;
  width: 153px;
}

.homepage .text-wrapper-29 {
  color: var(--gray-01);
  font-family: "Manrope", Helvetica;
  font-size: 14px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: normal;
  position: relative;
  width: fit-content;
}

.homepage .text-wrapper-30 {
  color: #000000;
  font-family: "Manrope", Helvetica;
  font-size: 32px;
  font-weight: 600;
  left: -81px;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  top: 2432px;
}
.interact-box {
  position: relative;
  bottom : 0;
  justify-content: flex-end;
  align-items: center;
  width: 1440px;
  height: 430px;
  display: flex;
  flex-direction: column;
}

.fb-page {
  position: absolute;
  left: 0;
}
.zalo-icon {
  position: fixed;
  right: 25px;
  bottom: 90px;
  width: 60px;
  height: 60px;
}

.frame-49 {
  background: linear-gradient(
    180deg,
    rgba(59, 93, 5, 0) 12.5%,
    rgba(59, 93, 5, 0.8) 59.38%,
    rgb(18.15, 48.87, 15.27) 100%
  );
  /* background-color: rgba(255, 255, 255, 1); */
  height: 800px;
  position: relative;
  width: 1440px;
}

.frame-49 .div {
  align-items: flex-start;
  display: flex;
  gap: 32px;
  left: 100px;
  position: relative;
  top: 389px;
  width: 1189px;
}

.frame-49 .div-2 {
  align-items: flex-start;
  display: inline-flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 10px;
  position: relative;
}

.frame-49 .text-wrapper {
  color: #ffffff;
  font-family: var(--h1-semibold-font-family);
  font-size: var(--h1-semibold-font-size);
  font-style: var(--h1-semibold-font-style);
  font-weight: var(--h1-semibold-font-weight);
  letter-spacing: var(--h1-semibold-letter-spacing);
  line-height: var(--h1-semibold-line-height);
  margin-top: -1px;
  position: relative;
  width: 454.27px;
}

.frame-49 .p {
  color: #ffffffcc;
  font-family: var(--h1-semibold-font-family);
  font-size: var(--18-medium-font-size);
  font-style: var(--18-medium-font-style);
  font-weight: var(--18-medium-font-weight);
  letter-spacing: var(--18-medium-letter-spacing);
  line-height: var(--18-medium-line-height);
  position: relative;
  width: fit-content;
}

.frame-49 .div-3 {
  align-items: flex-start;
  display: flex;
  flex: 1;
  flex-direction: column;
  flex-grow: 1;
  gap: 24px;
  position: relative;
}

.frame-49 .text-wrapper-2 {
  align-self: stretch;
  color: #ffffffcc;
  font-family: var(--24-semibold-font-family);
  font-size: var(--24-semibold-font-size);
  font-style: var(--24-semibold-font-style);
  /* font-weight: var(--24-semibold-font-weight); */
  letter-spacing: var(--24-semibold-letter-spacing);
  line-height: var(--24-semibold-line-height);
  margin-top: -1px;
  position: relative;
}

.frame-49 .text-wrapper-3 {
  align-self: stretch;
  color: #ffffffcc;
  font-family: var(--24-semibold-font-family);
  font-size: var(--24-semibold-font-size);
  font-style: var(--24-semibold-font-style);
  /* font-weight: var(--24-semibold-font-weight); */
  letter-spacing: var(--24-semibold-letter-spacing);
  line-height: var(--24-semibold-line-height);
  position: relative;
}
