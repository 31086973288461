.testimonials {
  align-items: flex-start;
  background-color: #ffffff;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  height: 318px;
  justify-content: space-between;
  overflow: hidden;
  padding: 32px;
  position: relative;
  width: 369px;
}

.testimonials .frame-4 {
  align-items: flex-start;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 16px;
  position: relative;
  width: 100%;
}

.testimonials .img {
  height: 32px;
  position: relative;
  width: 33.75px;
}

.testimonials .we-had-such-an {
  align-self: stretch;
  color: #000000;
  font-family: var(--16-medium-font-family);
  font-size: var(--16-medium-font-size);
  font-style: var(--16-medium-font-style);
  font-weight: var(--16-medium-font-weight);
  letter-spacing: var(--16-medium-letter-spacing);
  line-height: var(--16-medium-line-height);
  position: relative;
}

.testimonials .frame-5 {
  align-items: center;
  display: inline-flex;
  flex: 0 0 auto;
  gap: 16px;
  position: relative;
}

.testimonials .ellipse {
  height: 32px;
  object-fit: cover;
  position: relative;
  width: 32px;
}

.testimonials .content {
  align-items: flex-start;
  display: inline-flex;
  flex: 0 0 auto;
  flex-direction: column;
  position: relative;
}

.testimonials .our-team {
  color: #000000;
  font-family: var(--14-semibold-font-family);
  font-size: var(--14-semibold-font-size);
  font-style: var(--14-semibold-font-style);
  font-weight: var(--14-semibold-font-weight);
  letter-spacing: var(--14-semibold-letter-spacing);
  line-height: var(--14-semibold-line-height);
  margin-top: -1px;
  position: relative;
  width: fit-content;
}

.testimonials .frame-6 {
  align-items: center;
  display: inline-flex;
  flex: 0 0 auto;
  gap: 3px;
  position: relative;
}

.testimonials .star {
  align-items: flex-start;
  display: inline-flex;
  flex: 0 0 auto;
  position: relative;
}

.testimonials .star-2 {
  height: 10.85px;
  position: relative;
  width: 11.41px;
}

.testimonials .frame-7 {
  align-items: center;
  display: inline-flex;
  flex: 0 0 auto;
  gap: 2px;
  position: relative;
}

.testimonials .text-wrapper-4 {
  color: var(--gray-01);
  font-family: var(--12-regular-font-family);
  font-size: var(--12-regular-font-size);
  font-style: var(--12-regular-font-style);
  font-weight: var(--12-regular-font-weight);
  letter-spacing: var(--12-regular-letter-spacing);
  line-height: var(--12-regular-line-height);
  margin-top: -1px;
  position: relative;
  width: fit-content;
}

.testimonials .social-icons {
  height: 14px !important;
  position: relative !important;
  width: 14px !important;
}
