.card {
  height: 900px;
  width: 554px;
}

.card .c-ontainer {
  align-items: flex-start;
  border-radius: 8px;
  display: inline-flex;
  flex-direction: column;
  overflow: hidden;
  position: relative;
}

.card .div {
  align-items: flex-start;
  display: flex;
  flex: 0 0 auto;
  justify-content: space-between;
  padding: 32px;
  position: relative;
  width: 554px;
}

.card .frame-2 {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  gap: 8px;
  position: relative;
  width: 343px;
}

.card .tour-trecking {
  color: var(--black);
  font-family: "Manrope", Helvetica;
  font-size: 28px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: normal;
  margin-top: -1px;
  position: relative;
  width: fit-content;
}

.card .h-nh-tr-nh-a-ch-ng {
  align-self: stretch;
  color: var(--gray-01);
  position: relative;
}

.card .bullet-point {
  align-items: center;
  display: inline-flex;
  flex: 0 0 auto;
  gap: 8px;
  position: relative;
}

.card .rectangle {
  height: 12px;
  position: relative;
  width: 12px;
}

.card .text-wrapper {
  color: var(--gray-01);
  margin-top: -1px;
  position: relative;
  width: fit-content;
}

.card .bullet-point-2 {
  color: var(--gray-01);
  margin-top: -1px;
  position: relative;
  width: fit-content;
}

.card .bullet-point-3 {
  color: var(--gray-01);
  margin-top: -1px;
  position: relative;
  width: fit-content;
}

.card .frame-3 {
  align-items: flex-start;
  display: inline-flex;
  flex: 0 0 auto;
  gap: 8px;
  padding: 24px 0px 0px;
  position: relative;
}

.card .text-wrapper-2 {
  color: #839a3b;
  font-family: "Inter", Helvetica;
  font-size: 18px;
  font-weight: 700;
  letter-spacing: 0;
  line-height: normal;
  margin-top: -1px;
  position: relative;
  width: fit-content;
}

.card .arrow-forward {
  height: 24px;
  position: relative;
  width: 24px;
}

.card .ing {
  /* background-image: url(https://generation-sessions.s3.amazonaws.com/d492c08a3017c553696af269c3e39fe1/img/ing-6.png); */
  background-position: 50% 50%;
  background-size: cover;
  height: 600px;
  position: relative;
  width: 554px;
  object-fit: cover;
}
.card .ing > div {
  width: 100%;
}
.slider {
  width: 100%;
  height: 500px;
  object-fit: cover;
}

.card .hills-1 {
  background-image: url(https://generation-sessions.s3.amazonaws.com/d492c08a3017c553696af269c3e39fe1/img/hills-1-2@2x.png) !important;
  height: 120px !important;
  position: relative !important;
  width: 120px !important;
}

.card.default {
  background-color: #ffffff;
  border-radius: 8px;
  box-shadow: 0px 0px 0px #eb5567;
  overflow: hidden;
}

.card.hover .c-ontainer {
  background-color: #ffffff;
  box-shadow: 8px 8px 0px #eb5567;
  left: -8px;
  top: -8px;
}

.card.hover .h-nh-tr-nh-a-ch-ng {
  font-family: "Manrope", Helvetica;
  font-size: 16px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: normal;
}

.card.default .h-nh-tr-nh-a-ch-ng {
  font-family: var(--16-medium-font-family);
  font-size: var(--16-medium-font-size);
  font-style: var(--16-medium-font-style);
  font-weight: var(--16-medium-font-weight);
  letter-spacing: var(--16-medium-letter-spacing);
  line-height: var(--16-medium-line-height);
}

.card.hover .text-wrapper {
  font-family: "Manrope", Helvetica;
  font-size: 16px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: normal;
}

.card.default .text-wrapper {
  font-family: var(--16-medium-font-family);
  font-size: var(--16-medium-font-size);
  font-style: var(--16-medium-font-style);
  font-weight: var(--16-medium-font-weight);
  letter-spacing: var(--16-medium-letter-spacing);
  line-height: var(--16-medium-line-height);
}

.card.hover .bullet-point-2 {
  font-family: "Manrope", Helvetica;
  font-size: 16px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: normal;
}

.card.default .bullet-point-2 {
  font-family: var(--16-medium-font-family);
  font-size: var(--16-medium-font-size);
  font-style: var(--16-medium-font-style);
  font-weight: var(--16-medium-font-weight);
  letter-spacing: var(--16-medium-letter-spacing);
  line-height: var(--16-medium-line-height);
}

.card.hover .bullet-point-3 {
  font-family: "Manrope", Helvetica;
  font-size: 16px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: normal;
}

.card.default .bullet-point-3 {
  font-family: var(--16-medium-font-family);
  font-size: var(--16-medium-font-size);
  font-style: var(--16-medium-font-style);
  font-weight: var(--16-medium-font-weight);
  letter-spacing: var(--16-medium-letter-spacing);
  line-height: var(--16-medium-line-height);
}
